import React from "react"
import styled from "styled-components"
import GlobalFonts from "../font/fonts"
import wave from "../assets/Wave.svg"
import waveTop from "../assets/WaveTop.svg"

import google from "../assets/google.svg"
import instagram from "../assets/instagram.svg"
import linkedin from "../assets/linkedin.svg"
import youtube from "../assets/youtube.svg"

import app_store from "../images/icon-appstore.png"
import google_play from "../images/icon-google-play.png"


export function FooterContainer() {
  return (
    <FooterDiv>
    <GlobalFonts />
    <Container>
      <Wrapper>
        <Row>
          <Column>
            <Title>About Us</Title>
            <Link href="/support">Support</Link>
            
            <Link href="/terms-of-service">Terms Of Service</Link>
            <Link href="/privacy-policy">Privacy Policy</Link>
          </Column>
          <Column>
            <Title>Own A Home</Title>
            <Link href="/own-a-vacation-home">Own A Home Right Now</Link>
          </Column>

          <RightColumn>
            <Title>Social</Title>
            <SocialRow>
              <a href="https://cloudcastles.onelink.me/GiaO/web">
            <ImageL src={app_store} />
           </a>
            </SocialRow>      
            <SocialRow>
              <a href="https://play.google.com/store/apps/details?id=com.cloudcastles.bmarks&hl=en_US&gl=US">
            <ImageL src={google_play}/>
           </a>
            </SocialRow>            
            {/* <SocialRow> */}
            {/* <Image src={appstorewhite} /> */}
            {/* </SocialRow> */}
            <SocialRow>
            <Link href="https://www.instagram.com/cloudcastles.io/?hl=en">
              <Logo src={instagram} />
            </Link>
            {/* <Link href="#"> */}
            {/*   <Logo src={google} /> */}
            {/* </Link> */}
            <Link href="https://www.linkedin.com/company/cloud-castles/">
              <Logo src={linkedin} />
            </Link>
            {/* <Link href="#"> */}
            {/*   <Logo src={youtube} /> */}
            {/* </Link> */}
          </SocialRow>
          <SocialRow>
<Title2>© {new Date().getFullYear()}, Cloud Castles, Inc.{` `}</Title2>
          </SocialRow>
          </RightColumn>

        </Row>
      </Wrapper>
      <Title2>Built in California, for people to own real-estate anywhere.</Title2>
    </Container>
    </FooterDiv>
  )
}

export default FooterContainer

const Logo = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;

  margin: auto;
`
const Image = styled.img`
  position: absolute;  
  height: 40px;
  margin: 0px 0px 40px 30px;
`

const ImageL = styled.img`
  position: absolute;  
  height: 30px;
  margin: 0px 0px 40px 30px;
`


/*
const Wave = styled.img`
  position: absolute;
  width: 100%;
  z-index: 0;
    top: 3139.21px;
`

const WaveTop = styled.img`
  position: absolute;
  width: 100%;
  opacity:80%;
  z-index: 0;
    top: 3139.21px;
`
*/

export const Container = styled.div`
  padding: 80px 60px;
  width:100%;
  background-color: rgb(139, 190, 243, 1);
  background: radial-gradient(
    circle,
    rgb(139, 190, 243, 1) 0%,
    rgb(139, 190, 243, 0.6) 100%
  );
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  /* background: red; */
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
`

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 0px;
`



export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 20px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`


export const SocialRow = styled.div`
  margin: 10px 40px 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  justify-items: center;

`

export const Link = styled.a`
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
  &:hover {
    color: #ff9c00;
    transition: 200ms ease-in;
  }
`

export const Title = styled.p`
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: bold;
`

export const Title2 = styled.p`
  font-size: 11px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: bold;
  width:400%;
  display:flex;
  margin: auto;

`
export const Icon = styled.i`
  font-size: 18px;
  margin-right: 16px;
`
export const FooterDiv = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  left: 0px;
  top: 0.21px;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
